import React, { useEffect, useState } from 'react';

import HomeScreenBanner from '@/components/cards/HomeScreenBanner';
import useActiveCampaigns from '@/hooks/campaigns/useActiveCampaigns';
import useIntercomContext from '@/hooks/context/useIntercomContext';
import useUserTracking from '@/hooks/useUserTracking';
import useFeatureFlags from '@/services/FeatureFlag';

import ActiveCampaignsCard from '../components/cards/ActiveCampaignsCard';
import PromotionCards from '../components/cards/PromotionCards';
import SocialTilesCard from '../components/cards/SocialTilesCard';
import ToDoListCard from '../components/cards/ToDoListCard';
import Subscription from '../components/subscription/Subscription';
import UserHeader from '../components/utility/navigation/UserHeader';

const HomePage = () => {
  const [isCampaigns, setIsCampaigns] = useState<boolean>(true);
  const userTracking = useUserTracking();
  const { isHomeScreenBannerEnabled } = useFeatureFlags();

  const { dispatchActivateIntercom, dispatchDeactivateIntercom } = useIntercomContext();

  const { activeCampaigns } = useActiveCampaigns();

  useEffect(() => {
    dispatchActivateIntercom('home');
    return () => {
      dispatchDeactivateIntercom('home');
    };
  }, [dispatchActivateIntercom, dispatchDeactivateIntercom]);

  useEffect(() => {
    if (userTracking) userTracking.userHomeScreenViewed();
  }, [userTracking]);

  useEffect(() => {
    if (
      activeCampaigns?.playlistPitches.recentlyReviewed === 0 &&
      activeCampaigns?.socialAds.active === 0 &&
      activeCampaigns.playlistPitches.pending === 0
    )
      return setIsCampaigns(false);

    setIsCampaigns(true);
  }, [activeCampaigns]);

  return (
    <div data-testid="home-page" className="page-content">
      <UserHeader title={'PAGE-TITLES.HOME'} isHome />
      {isHomeScreenBannerEnabled && (
        <div className="mt20 w100p">
          <HomeScreenBanner />
        </div>
      )}
      <div className="mt20 w100p">
        <Subscription type="banner-small" />
      </div>

      {/* Large screens */}
      <div className="hide-lg-down">
        <div className="d-flex gap20 mt20">
          {isCampaigns && (
            <div className="w40p">
              <ActiveCampaignsCard />
            </div>
          )}
          <div className="w60p flex-grow">
            <SocialTilesCard />
          </div>
        </div>
        <div className="d-flex gap20 mt20">
          <div className="w60p">
            <PromotionCards />
          </div>
          <div className="w40p">
            <ToDoListCard />
          </div>
        </div>
      </div>
      {/* Small Screens */}
      <div className="hide-lg-up">
        <div className="d-flex flex-d-col gap20 mt20">
          <SocialTilesCard />
          {isCampaigns && <ActiveCampaignsCard />}
          <PromotionCards />
          <ToDoListCard />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
